import React, {useEffect} from 'react';
import './App.css';
import "animate.css/animate.min.css"; 

import Terminal, { ColorMode, LineType } from 'react-terminal-ui';
import { IconButton } from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';

import { DiGithubBadge, DiReact } from 'react-icons/di';
import { GrLinkedinOption } from 'react-icons/gr';
import { HiOutlineMail } from 'react-icons/hi';
import { FaScroll, FaNodeJs, FaAws, FaJava } from 'react-icons/fa';
import { FiFigma } from 'react-icons/fi';
import { CgLinear } from 'react-icons/cg';
import { SiFirebase, SiKotlin, SiAsana, SiNotion, SiMysql, SiHeroku, SiPython, SiFlask, SiJira, SiConfluence, SiRoblox } from 'react-icons/si';

import DailyDashboard from './img/daily-dashboard.png';
import Flamingle from './img/flamingle.jpeg';
import Ladder from './img/ladder.jpg';
import BigTable from './img/ccloud-bigtable-sink-icon.png';
import LanguageSwitcher from './img/language_switcher.jpeg';
import PickUpAI from './img/pick-up-ai.jpg';
import StarWarsWeather from './img/star-wars-weather.png';
import Varun from './img/varun.jpg';

const Project = ({title, subheader, description, link, image, icons}) => {
  return(
    <>
      <div>
        <div style={{fontWeight: "bold", fontSize: '48px'}}>{title}</div>
        <br />
        <div style={{fontWeight: "bold", fontSize: '16px'}}>{subheader}</div>
        <br />
        <ul className="bullets" style={{maxWidth: '900px'}}>
          {description.map(item => <li key={item}>{item}</li>)}
        </ul>
        <br />
        <div style={{fontSize: '16px'}}>Technologies Used:</div>
        <br />
        <div className="flex">{icons.map(icon => (icon))}</div>
      </div>
      <div>
      <Card
        img={image}
        title={title}
        link={link}
        />
      </div>
    </>
  )
}

const Card  = ({img, title, link}) => {
  return(
      <div className="card">
        <a href={link} target="_blank" rel="noreferrer">
        <img src={img} alt={title} />
        </a>
      </div>
  )
}

const App = () => {
  const inputColor = '#5cff5c';
  const iconSpacing = "10px";

  const terminalLineData = [
    {type: LineType.Input, value: 'From:'},
    {type: LineType.Output, value: <span style={{color: inputColor}}>Plainsboro, New Jersey</span>},
    {type: LineType.Input, value: 'Currently Pursuing:'},
    {type: LineType.Output, value: <span style={{color: inputColor}}>Product Management</span>},
    {type: LineType.Input, value: 'Tools and Technologies:'},
    {type: LineType.Output, value: <span style={{color: inputColor}}>Figma, Confluence, JIRA, Amplitude, Tableau, AWS,<br/>Google Firebase, Data Science/Machine Learning, Git</span>},
    {type: LineType.Input, value: 'Programming:'},
    {type: LineType.Output, value: <span style={{color: inputColor}}>JavaScript, Python, C++, SQL, MongoDB, React Native, React,<br/>Redux, Java, Flask, Go, Swift, HTML/CSS, Kotlin</span>},
    {type: LineType.Input, value: 'Interests:'},
    {type: LineType.Output, value: <span style={{color: inputColor}}>Boston Celtics, Ratatouille, Bollywood, Drinking Tea</span>},
    {type: LineType.Input, value: 'Spotify Top Artists:'},
    {type: LineType.Output, value: <span style={{color: inputColor}}>Bruno Mars, Cannons, Dominic Fike, Kid Cudi, Taylor Swift,<br/>Ariana Grande, Lady Gaga</span>},
    {type: LineType.Input, value: 'Some of my work:'},
    {type: LineType.Output, value: (<span style={{color: inputColor}}>Click <a href="#projects" style={{color: "#52ffff"}}>here!</a></span>)},
  ];

  const projects = [
    {
      title: "Roblox",
      subheader: "Product Manager",
      description: ["Strategized launch plan, evaluated engineering trade-offs, and ensured alignment with 3 cross-functional teams; shipped feature allowing 37.1 million users to switch in-game language; increased playtime for top games by 1.4%", "Scoped out feature roadmap by synthesizing 12+ user interviews and interpreting data analytics dashboards and KPIs; pioneered documentation creation for 4 new features on roadmap by constructing product specs and aligning with 2 teams"],
      link: "https://devforum.roblox.com/t/localization-api-update-for-upcoming-in-experience-language-switcher/1974795#update-october-14-2022-1",
      image: LanguageSwitcher,
      icons: [<a href="https://www.roblox.com/create/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiRoblox size="30px"/></a>,
              <a href="https://www.atlassian.com/software/jira" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiJira size="30px"/></a>,
              <a href="https://www.atlassian.com/software/confluence" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiConfluence size="30px"/></a>,
      ]
    },
    {
      title: "Confluent",
      subheader: "Software Engineer",
      description: ["Defined product requirements, collected user feedback, mapped out cloud errors, and reviewed connector documentation to develop user interface used by 20+ customers", "Deployed connector on Confluent Cloud, generating over $4,000 of revenue per month", "Launched connector to enable the transfer of 40 TB/month on the cloud from Apache Kafka to Google Cloud Bigtable database"],
      link: "https://docs.confluent.io/kafka-connect-gcp-bigtable/current/overview.html",
      image: BigTable,
      icons: [<a href="https://www.java.com/en/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FaJava size="30px"/></a>,
              <a href="https://www.atlassian.com/software/jira" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiJira size="30px"/></a>,
              <a href="https://www.atlassian.com/software/confluence" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiConfluence size="30px"/></a>,
      ]
    },
    {
      title: "Nova",
      subheader: "Software Engineer",
      description: ["Worked at a venture-backed startup to develop its professional development community platform with over 40,000 registered users", "Created Invites page from the ground-up which boosted new user acquisition by 554% in one week"],
      link: "https://joinnova.co/invites",
      image: Ladder,
      icons: [<a href="https://reactjs.org" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><DiReact size="30px"/></a>, 
              <a href="https://firebase.google.com/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiFirebase size="30px"/></a>, 
              <a href="https://nodejs.org/en/about/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FaNodeJs size="30px"/></a>, 
              <a href="https://figma.com/about" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FiFigma size="30px"/></a>,
              <a href="https://www.notion.so/about" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiNotion size="30px"/></a>,
              <a href="https://linear.app/about" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><CgLinear size="30px"/></a>
      ]
    },
    {
      title: "Flamingle",
      subheader: "Product Manager / Software Engineer",
      description: ["Managed an 8 intern team and took part in the development of a mobile app that leverages asynchronous video profiles and messaging to foster fruitful connections", "Directed the development of the user authentication and implemented a video post feed and messaging"],
      link: "https://flamingleapp.com/",
      image: Flamingle,
      icons: [<a href="https://reactjs.org" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><DiReact size="30px"/></a>, 
              <a href="https://aws.amazon.com/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FaAws size="30px"/></a>, 
              <a href="https://nodejs.org/en/about/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FaNodeJs size="30px"/></a>, 
              <a href="https://kotlinlang.org/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiKotlin size="30px"/></a>, 
              <a href="https://figma.com/about" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FiFigma size="30px"/></a>,
              <a href="https://asana.com" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiAsana size="30px"/></a>,
      ]
    },
    {
      title: "Daily Dashboard",
      subheader: "App",
      description: ["Built web application which utilizes datasets and a weather API to show users daily recommendations for songs, cocktails, movies, and food, as well as the local weather and days to an important event"],
      link: "https://www.dailydashboard-app.com/",
      image: DailyDashboard,
      icons: [<a href="https://reactjs.org" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><DiReact size="30px"/></a>, 
              <a href="https://www.mysql.com/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiMysql size="30px"/></a>, 
              <a href="https://nodejs.org/en/about/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FaNodeJs size="30px"/></a>, 
              <a href="https://www.heroku.com/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiHeroku size="30px"/></a>,        
      ]
    },
    {
      title: "Star Wars Weather",
      subheader: "App",
      description: ["Wrote a web application which uses a weather API to display the weather in any part of the world and compares the weather to a planet in the Star Wars universe"],
      link: "https://star-wars-weather-276615.ue.r.appspot.com/",
      image: StarWarsWeather,
      icons: [<a href="https://reactjs.org" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><DiReact size="30px"/></a>, 
              <a href="https://nodejs.org/en/about/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FaNodeJs size="30px"/></a>, 
      ]
    },
    {
      title: "Pick Up AI",
      subheader: "App",
      description: [<>Created app that generates its own, novel pickup lines based on a <a href="https://openai.com/blog/better-language-models/" target="_blank" rel="noreferrer" style={{color: "#52ffff"}}>GPT-2</a> artificial intelligence model that was trained on Twitter data scraped from various pickup line accounts</>],
      link: "https://github.com/Varun-S-Nair/pick-up-ai",
      image: PickUpAI,
      icons: [<a href="https://reactjs.org" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><DiReact size="30px"/></a>, 
              <a href="https://nodejs.org/en/about/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><FaNodeJs size="30px"/></a>, 
              <a href="https://www.python.org/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiPython size="30px"/></a>, 
              <a href="https://flask.palletsprojects.com/en/2.0.x/" target="_blank" rel="noreferrer" style={{marginRight: iconSpacing}} ><SiFlask size="30px"/></a>, 
      ]
    }
  ];

  useEffect(() => {
    document.body.style.backgroundColor = "#13294b";
  }, []);

  return (
    <div className="App">
      <head>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />
      </head>
      <div className="top-container">
      <ScrollAnimation className="top-container" animateIn='fadeIn' animateOut='fadeOut'>
        <div className="top-container">
          <div className="Name">
            Varun<br/>Nair
          </div>

          <ul className="bullets">
            <li>Product Manager at <a href="https://www.hubspot.com/" target="_blank" rel="noreferrer"><b style={{color: "#FF7A59"}}>HubSpot</b></a></li>
            <li>Prev. Product Manager at <a href="https://corp.roblox.com/" target="_blank" rel="noreferrer"><b style={{color: "#636667"}}>Roblox</b></a></li>
            <li>Prev. Software Engineer at <a href="https://www.confluent.io/" target="_blank" rel="noreferrer"><b style={{color: "#37b0ba"}}>Confluent</b></a></li>
          </ul>

          <div class="break"></div>

          <div className="icons">
            <IconButton href="https://github.com/Varun-S-Nair/" target="_blank" rel="noreferrer" color="inherit" aria-label="Github">
              <DiGithubBadge size="27px" />
            </IconButton>
            <IconButton href="https://www.linkedin.com/in/varunsnair2/" target="_blank" rel="noreferrer" color="inherit" aria-label="Linkedin">
              <GrLinkedinOption size="27px" />
            </IconButton>
            <IconButton href="mailto:varunsn2@illinois.edu" target="_blank" rel="noreferrer" color="inherit" aria-label="Email">
              <HiOutlineMail size="27px" />
            </IconButton>
            <IconButton href="https://drive.google.com/file/d/18FOkuwp1vvcXEpw8Uf-4TtpYn7XcdYJr/view?usp=sharing" target="_blank" rel="noreferrer" color="inherit" aria-label="Resume">
              <FaScroll size="27px" />
            </IconButton>
          </div>
        </div>

        <img src={Varun} width={160} height={160} style={{marginRight: '80px'}}/>

        <div class="break"></div>

        <div className="terminal" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-all', maxWidth: '100%', overflowX: 'hidden'}} >
          <Terminal name='Varun Nair --bash--' colorMode={ ColorMode.Dark } lineData={ terminalLineData } style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-all', maxWidth: '100%', overflowX: 'hidden'}} />
        </div> 
      </ScrollAnimation>

        <div class="break"></div>  

        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
          <div className="section-title" id="projects">
            Projects
          </div>
        </ScrollAnimation>
      
        <div class="break"></div>
      </div>


      {projects.map(project => (
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
          <div className='cards'>
            <Project 
              title={project.title}
              subheader={project.subheader}
              description={project.description}
              link={project.link}
              image={project.image}
              icons={project.icons}
            />
          </div> 
        </ScrollAnimation>
      ))}
    </div>
  );
}

export default App;
